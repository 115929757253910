import Section from 'components/section/components/Section';
import useMakeWork from './useMakeWork';
import { numbersType, WORKS } from '../../constants/works';
import CardMakeWork from 'components/card-make-work/components/CardMakeWork';
import { useTranslation } from 'react-i18next';

const MakeWork = () => {
  const { title, works } = useMakeWork();
  const { t } = useTranslation();

  return (
    <Section title={t(title)}>
      {works?.map(({ id, title, iconNumber, description, image }) => {
        return (
          <CardMakeWork
            key={id}
            title={t(title)}
            iconNumber={WORKS.numbers[iconNumber as numbersType]}
            description={t(description!)}
            image={image!}
          />
        );
      })}
    </Section>
  );
};

export default MakeWork;
