import { graphql, useStaticQuery } from 'gatsby';
import { IMakeWorkResponse } from 'interfaces/interfaces';

const useMakeWork = () => {
  const {
    markdownRemark: {
      frontmatter: {
        english: { title, works },
      },
    },
  } = useStaticQuery<IMakeWorkResponse>(graphql`
    query makeWork {
      markdownRemark(frontmatter: { type: { eq: "makeWork" } }) {
        frontmatter {
          english {
            title
            works {
              id
              iconNumber
              title
              description
              image {
                childImageSharp {
                  gatsbyImageData(height: 250, width: 400)
                }
              }
            }
          }
        }
      }
    }
  `);

  return { title, works };
};

export default useMakeWork;
