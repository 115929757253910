import styled from 'styled-components';
import { COLORS } from 'globalStyles/colors';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
  MOBILE_375,
  MOBILE_425,
  TABLET_1024,
  TABLET_768,
} from '../../../constants/breakpoints';

const CardMakeWorkContainer = styled.div`
  width: 40rem;
  flex: 0 0 calc(33.333% - 3rem);
  @media screen and (max-width: ${TABLET_1024}) {
    flex: 0 0 48%;
    &:nth-child(2) {
      margin-top: 58px;
    }
    &:nth-child(3) {
      margin-top: 58px;
    }
  }
  @media screen and (max-width: ${MOBILE_425}) {
    flex: 0 0 100%;
  }
  @media screen and (max-width: ${MOBILE_375}) {
    flex: 0 0 100%;
  }
`;

const CardMakeWorkNumber = styled.div`
  display: flex;
  justify-content: center;
`;

const CardMakeWorkTitleWrapper = styled.div`
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
`;

const CardMakeWorkTitle = styled.h3`
  font: 800 24px 'Avenir', sans-serif;
  font-style: normal;
  line-height: 33px;
  text-align: center;
  color: ${COLORS.white};
`;

const CardMakeWorkDescriptionWrapper = styled.div`
  padding-top: 1.6rem;
  @media screen and (max-width: ${TABLET_768}) {
    width: 100%;
  }
`;

const CardMakeWorkDescription = styled.p`
  font: 400 20px 'Avenir', sans-serif;
  font-style: normal;
  line-height: 27px;
  display: flex;
  text-align: justify;
  color: ${COLORS.tertiaryGrey};
`;

const CardMakeWorkImage = styled(GatsbyImage)`
  width: 100%;
  height: 26rem;
  background: ${COLORS.white};
  border: 0.2rem solid ${COLORS.tertiaryGrey};
  box-sizing: border-box;
  border-radius: 0.8rem;
  @media screen and (max-width: ${TABLET_768}) {
    width: 100%;
    height: 50%;
  }
  @media screen and (max-width: ${MOBILE_425}) {
    width: 100%;
    height: 50%;
  }
  @media screen and (max-width: ${MOBILE_375}) {
    width: 100%;
    height: 50%;
  }
`;

export {
  CardMakeWorkContainer,
  CardMakeWorkNumber,
  CardMakeWorkTitleWrapper,
  CardMakeWorkDescriptionWrapper,
  CardMakeWorkDescription,
  CardMakeWorkImage,
  CardMakeWorkTitle,
};
