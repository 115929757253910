import MonthlyIncome from 'assets/images/ui-elements/cards/monthly-income.svg';
import Maintenance from 'assets/images/ui-elements/cards/maintenance.svg';
import Insurance from 'assets/images/ui-elements/cards/insurance.svg';
import Repairs from 'assets/images/ui-elements/cards/repairs.svg';
import ChargeLevel from 'assets/images/ui-elements/cards/charge-level.svg';
import RegularCarInspection from 'assets/images/ui-elements/cards/regular-car-inspection.svg';

export type partnershipProgramType =
  | 'monthly-income'
  | 'maintenance'
  | 'insurance'
  | 'repairs'
  | 'charge-level'
  | 'regular-car-inspection';

export const PARTNERSHIP_PROGRAM = {
  'monthly-income': MonthlyIncome,
  maintenance: Maintenance,
  insurance: Insurance,
  repairs: Repairs,
  'charge-level': ChargeLevel,
  'regular-car-inspection': RegularCarInspection,
};
