import usePartnershipProgram from './usePartnershipProgram';
import Section from 'components/section/components/Section';
import CardInfo from 'components/card-info/components/CardInfo';
import {
  PARTNERSHIP_PROGRAM,
  partnershipProgramType,
} from '../../constants/partnershipProgram';
import { useTranslation } from 'react-i18next';

const PartnershipProgram = () => {
  const { title, advantages } = usePartnershipProgram();
  const { t } = useTranslation();

  return (
    <Section title={t(title)}>
      {advantages?.map(({ id, title, icon, description, height }) => (
        <CardInfo
          key={id}
          title={t(title)}
          description={t(description)}
          icon={PARTNERSHIP_PROGRAM[icon as partnershipProgramType]}
          withOpen={false}
          height={height}
        />
      ))}
    </Section>
  );
};

export default PartnershipProgram;
