import { FC } from 'react';
import * as S from 'components/card-make-work/styles/styled';
import { ICardMakeWork } from 'components/card-make-work/interfaces/interfaces';
import { getImage } from 'gatsby-plugin-image';

const CardMakeWork: FC<ICardMakeWork> = ({
  iconNumber: IconNumber,
  title,
  description,
  image,
}) => {
  const img = getImage(image);
  return (
    <S.CardMakeWorkContainer>
      <S.CardMakeWorkNumber>
        <IconNumber />
      </S.CardMakeWorkNumber>
      <S.CardMakeWorkTitleWrapper>
        <S.CardMakeWorkTitle>{title}</S.CardMakeWorkTitle>
      </S.CardMakeWorkTitleWrapper>
      <S.CardMakeWorkImage image={img} alt="card" />
      <S.CardMakeWorkDescriptionWrapper>
        <S.CardMakeWorkDescription>{description}</S.CardMakeWorkDescription>
      </S.CardMakeWorkDescriptionWrapper>
    </S.CardMakeWorkContainer>
  );
};

export default CardMakeWork;
